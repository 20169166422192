import ResumeSection from '../resume-section/resume-section'
import {RangedEntry} from '../entry/entry'

type WorkExperienceType = {
	isDarkModeOn: boolean
}

const WorkExperience = ({ isDarkModeOn }: WorkExperienceType) => {
	return (
		<ResumeSection title='Experience' isDarkModeOn={isDarkModeOn} >
			<div>
				<RangedEntry title={'Career Break - Pursuing Passion Projects'} startMonth={'June'} startYear={2023} boldTitle />
				<ul>
					<li>See mission statement</li>
				</ul>
			</div>
			<div>
				<RangedEntry title='Capital One' startMonth='Dec' startYear={2018} boldTitle endMonth="May" endYear={2023}/>
				<RangedEntry title='Lead Software Engineer / Manager' startMonth='Feb' startYear={2022} endMonth="May" endYear={2023} subTitle />
				<ul>
					<li>Team lead of 6 software engineers supporting internal and external integrations with the Multi-Lender Platform in Auto Finance.</li>
					<li>Enhanced platform APIs to utilize an on-behalf-of-identity header so that the system could know the
						originating context of a micro front end. Coordinated rollout across 7 teams.
					</li>
					<li>Led Spring Boot upgrade effort across 13 components owned by the team.</li>
					<li>Enhanced a dealer settings tool so that a dealer could choose their Capital One Lender Partner(s) without
						need for a manual process.
					</li>
					<li>Designed and led rollout of a new system to handle platform tenant configurations as code, utilizing S3, DynamoDB,
						and SQS
					</li>
				</ul>
				<RangedEntry title="Senior Software Engineer" startMonth="Feb" startYear={2020} endMonth="Feb" endYear={2022} subTitle />
				<ul>
					<li>Developed an auto dealer website button that enables a customer to get prequalified with an auto loan from Capital One. The button is now on thousands of dealer sites.</li>
					<li>Improved button load time performance from {'>'} 4 seconds to {'<'} 1 second by migrating to web components.</li>
					<li>Integrated with custom Identity Provider (IDP) so that customers could securely stay logged in across dealer websites.</li>
					<li>Enhanced dealer website experience by adding the capability to get prequalified with a Capital One Lender Partner, increasing the overall customer approval rates by 50%.</li>
				</ul>
				<RangedEntry title='Software Engineer' startMonth='Dec' startYear={2018} endMonth='Feb' endYear={2020} subTitle />
				<ul>
					<li>Member of the Eno Chatbot team, supporting several Scala and Java components.</li>
					<li>Scaled chatbot platform to support more than 500 users per second.</li>
					<li>Created performance testing automation workflow, enabling engineers to execute, evaluate and archive tests with variable load profiles at the click of a button.</li>
					<li>Developed webservice to collect and publish customer related events into a data lake for use by analytic teams.</li>
					<li>Provided first-line, on-call support for production components by maintaining an incident response time of less than 5 minutes, engaging upstream/downstream business partners when needed, analyzing impact numbers and determining root-cause analysis.</li>
				</ul>
			</div>
			<div>
				<RangedEntry title='State Farm' startMonth='Jan' startYear={2016} endMonth='Nov' endYear={2018} boldTitle/>
				<RangedEntry title='Software Developer III' startMonth='Sept' startYear={2017} endMonth='Nov' endYear={2018} subTitle />
				<RangedEntry title='Software Developer II' startMonth='Jan' startYear={2016} endMonth='Sept' endYear={2017} subTitle />
				<ul>
					<li>Team leader of 8 software developers for an agile team specialized in providing big data services in support of the roll out of a new policy admin system.</li>
					<li>Created multiple processes in Apache Spark and Scala to migrate and transform data from customer- and agent-facing applications into HDFS, preparing the data for analytic teams.</li>
					<li>Developed workflows to ingest data at a rate of 10 million transactions per day.</li>
					<li>Integrated first new billing solution in 20+ years by creating, modifying, and supporting 12 different REST, SOAP, ESB, and UI applications.</li>
				</ul>
			</div>
		</ResumeSection>
	)
}

export default WorkExperience
