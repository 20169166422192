import './entry.css'

type EntryType = {
	title: String,
	year: number | String,
	boldTitle?: boolean
}

export const Entry = ({ title, year, boldTitle }: EntryType) => {
	return (
		<div className='entry__container'>
			{
				boldTitle
					? <p><strong>{title}</strong></p>
					: <p>{title}</p>
			}
			<p>{year}</p>
		</div>
	)
}

type LinkEntryType = {
	text: string,
	link: string
}
export const LinkEntry = ({ text, link }: LinkEntryType) => {
	return (
		<div className='entry__container'>
			<a className='entry__container__link' href={link}>{text}</a>
		</div>
	)
}

type RangedEntryType = {
	title: String,
	startMonth: String,
	startYear: number,
	endMonth?: String,
	endYear?: number,
	boldTitle?: boolean,
	subTitle?: boolean
}

export const RangedEntry = ({ title, startMonth, startYear, endMonth, endYear, boldTitle, subTitle}: RangedEntryType) => {
	const endValue = endMonth == null || endYear == null ? 'Current' : `${endMonth} ${endYear}`
	const titleClass = subTitle ? 'entry__container__subtitle' : '';

	return (
		<div className='entry__container'>
			{
				boldTitle
					? <p className={titleClass}><strong>{title}</strong></p>
					: <p className={titleClass}>{title}</p>
			}
			<p>{startMonth} {startYear} - {endValue}</p>
		</div>
	)
}
