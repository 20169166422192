import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import Resume from './resume/resume'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Resume />
  </React.StrictMode>
)
