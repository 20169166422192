import ResumeSection from '../resume-section/resume-section'

type MissionStatementType = {
	isDarkModeOn: boolean
}

const MissionStatement = ({ isDarkModeOn }: MissionStatementType) => {
	return (
		<ResumeSection title='Mission Statement' isDarkModeOn={isDarkModeOn} >
			<p>Leah is a <strong>software engineer</strong> with <strong>8 years of experience</strong> across a wide variety of technologies. She is passionate about clean code, keeping it simple, and loves collaborating with other developers. Her strongest skill set is on the backend, but she loves to jump in wherever there is the greatest need.</p>
			<p>During the summer of 2023, Leah decided to take some time away from her tech career to pursue artistic passions. Her dream since she was a teenager was to one day become a full-time artist. However, after spending many months working on art, she missed the intellectual gratification that comes with software engineering. With renewed energy, Leah is excited to get back into the tech industry and collaborate with others who are just as passionate about their craft.</p>
		</ResumeSection>
	)
}

export default MissionStatement
