import ResumeSection from '../resume-section/resume-section'

type SkillsType = {
	isDarkModeOn: boolean
}

const Skills = ({ isDarkModeOn }: SkillsType) => {
	return (
		<ResumeSection title='Skills' isDarkModeOn={isDarkModeOn} >
			<div>
				<p>Top Skills:</p>
				<ul>
					<li><strong>Java</strong> - 7+ years</li>
					<li><strong>JavaScript/TypeScript</strong> - 4+ years</li>
					<li><strong>HTML/CSS</strong> - 4+ years</li>
					<li><strong>Spring/Springboot</strong> - 7+ years</li>
					<li><strong>React</strong> - 4+ years</li>
					<li><strong>AWS</strong> - 5+ years</li>
					<li><strong>Jenkins</strong> - 7+ years</li>
					<li><strong>Kafka</strong> - 5+ years</li>
					<li><strong>SQL</strong> - 5+ years</li>
					<li><strong>Dynamo DB</strong> - 2+ years</li>
					<li><strong>Splunk</strong> - 7+ years</li>
					<li><strong>New Relic</strong> - 4+ years</li>
					<li><strong>Scala</strong> - 2+ years</li>
					<li><strong>Web Components</strong> - 1+ years</li>
					<li><strong>Docker</strong> - 5+ years</li>
				</ul>
			</div>
			<div>
				<p>Familiar with:</p>
				<ul>
					<li><strong>Python</strong> - 1 year</li>
					<li><strong>Bash</strong> - 2 years</li>
					<li><strong>Kotlin</strong> - 2 years</li>
					<li><strong>Rust</strong> - &lt; 1 year</li>
					<li><strong>Apache Spark</strong> - 1 year</li>
					<li><strong>Akka</strong> - 1 year</li>
					<li><strong>Apache Cassandra</strong> - 1+ years</li>
					<li><strong>Terraform</strong> - &lt; 1 year</li>
					<li><strong>Groovy</strong> - 2+ years</li>
				</ul>
			</div>
		</ResumeSection>
	)
}

export default Skills
