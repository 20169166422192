import ResumeSection from '../resume-section/resume-section'
import {Entry} from '../entry/entry'

type PersonalProjectsType = {
	isDarkModeOn: boolean
}

const PersonalProjects = ({ isDarkModeOn }: PersonalProjectsType) => {
	return (
		<ResumeSection title='Personal Projects' isDarkModeOn={isDarkModeOn}>
			<Entry title='Tetris' year={2021} boldTitle />
			<p>
				Created a clone of Tetris in order to improve skills in TypeScript, React, and Redux
			</p>
			<Entry title='Intel 8080 Emulator' year={2019} boldTitle />
			<p>
				Written in Rust
			</p>
		</ResumeSection>
	)
}

export default PersonalProjects
