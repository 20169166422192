import ResumeSection from '../resume-section/resume-section'
import {Entry, LinkEntry} from '../entry/entry'

import './certifications.css'

type CertificationsType = {
	isDarkModeOn: boolean
}

const Certifications = ({ isDarkModeOn }: CertificationsType) => {
	return (
		<ResumeSection title='Certifications' isDarkModeOn={isDarkModeOn}>
			<Entry title='AWS Solutions Architect Associate' year={2024} boldTitle />
			<LinkEntry text={'Verification: 94f2d0399f6e47c188d38317a93967c1'} link={'https://cp.certmetrics.com/amazon/en/public/verify/credential/94f2d0399f6e47c188d38317a93967c1'} />
		</ResumeSection>
	)
}

export default Certifications
