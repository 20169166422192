import './resume-section.css'

type ResumeSectionType = {
	children?: any,
	isDarkModeOn: boolean,
	title: string
}

const ResumeSection = ({ children, isDarkModeOn, title }: ResumeSectionType) => {
	const darkModeToggleModifier = isDarkModeOn ? '--dark' : ''

	return (
		<section>
			<h2 className={`resume__resume-section__title${darkModeToggleModifier}`}>{title}</h2>
			{children}
		</section>
	)
}

export default ResumeSection
