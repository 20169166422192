import ResumeSection from '../resume-section/resume-section'
import {Entry} from '../entry/entry'

type EducationType = {
	isDarkModeOn: boolean
}

const Education = ({ isDarkModeOn }: EducationType) => {
	return (
		<ResumeSection title='Education' isDarkModeOn={isDarkModeOn} >
			<Entry title='B.S. Computer Science' year={2015} boldTitle />
			<Entry title='University of Texas at Dallas' year={'GPA: 3.85 / 4.00'} />
		</ResumeSection>
	)
}

export default Education
